@import '../../pages/page-include';
@import '../components/article-tile';

.article-page-wrapper {
    margin-top: 92px;

    @include mq($tablet-max) {
        margin-top: 62px;
    }
}

.article-container {

    @include mq($not-mobile) {
        display: flex;
        flex-wrap: wrap;
    }
}
