@import '../../pages/page-include';

.article-tile__image {
    @include image-aspect-ratio;

    transition: opacity 0.3s ease-in-out;
}

.article-tile {
    width: 100%;
    transition: background-color 0.3s ease-in-out;

    @include mq($not-mobile-tablet) {
        width: 33.3333%;
        flex-basis: 33.3333%;
    }

    &:hover {
        background-color: $grey1;

        .article-tile__image {
            opacity: 0.7;
        }
    }

    @include mq($tablet-only) {
        width: 50%;
        flex-basis: 50%;
    }
}

.article-tile__image-container {
    position: relative;
    padding-bottom: $ratio-3-2;
}

.article-tile__content {
    padding: $padding-m $padding-m 100px $padding-m;

    @include mq($mobile-max) {
        padding: $padding-s $padding-s 50px $padding-s;
    }
}

.article-tile__date {
    font-size: 11px;
}

.article-tile__text {
    padding: $padding-xs 0;
}

.article-tile__link {
    font-size: 13px;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}
