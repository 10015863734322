/************ Skandinavisk colors ***********/
/******* Aspect ratios *****/
/****** Transition *******/
/************ Skandinavisk colors ***********/
/******* Aspect ratios *****/
/****** Transition *******/
.article-tile__image {
  display: block;
  width: 100%;
  height: 100%;
  max-width: none;
  max-height: none;
  object-fit: cover;
  object-position: 50% 50%;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s ease-in-out;
}

.article-tile {
  width: 100%;
  transition: background-color 0.3s ease-in-out;
}
@media only screen and (min-width: 1200px) {
  .article-tile {
    width: 33.3333%;
    flex-basis: 33.3333%;
  }
}
.article-tile:hover {
  background-color: #f4f4f4;
}
.article-tile:hover .article-tile__image {
  opacity: 0.7;
}
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .article-tile {
    width: 50%;
    flex-basis: 50%;
  }
}

.article-tile__image-container {
  position: relative;
  padding-bottom: 66.66%;
}

.article-tile__content {
  padding: 30px 30px 100px 30px;
}
@media only screen and (max-width: 767px) {
  .article-tile__content {
    padding: 16px 16px 50px 16px;
  }
}

.article-tile__date {
  font-size: 11px;
}

.article-tile__text {
  padding: 10px 0;
}

.article-tile__link {
  font-size: 13px;
  text-decoration: underline;
}
.article-tile__link:hover {
  text-decoration: none;
}

.article-page-wrapper {
  margin-top: 92px;
}
@media only screen and (max-width: 1199px) {
  .article-page-wrapper {
    margin-top: 62px;
  }
}

@media only screen and (min-width: 768px) {
  .article-container {
    display: flex;
    flex-wrap: wrap;
  }
}